import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import MovieListPage from './Pages/MovieListPage';
import MovieDetailPage from './Pages/MovieDetailPage';
import VideoPlayerPage from './Pages/VideoPlayerPage';
const router = createBrowserRouter([
  {
    path: "/",
    element: <MovieListPage />,
  },
  {
    path: "/movie/:dbID",
    element: <MovieDetailPage />,
  },
  {
    path: "/play",
    element: <VideoPlayerPage />,
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;