import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai"; // icons form react-icons
import { IconContext } from "react-icons";
import ReactPaginate from "react-paginate";


function guidGenerator() {
  var S4 = function() {
     return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  };
  return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}


function MovieListPage() {
  const [movies, setMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchCurrentPage, setSearchCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [rnId, setRnId] = useState("divKey12354");
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSearch) {
          return;
        }
        if (searchQuery == "") {
          setSearchCurrentPage(1);
        }
        setIsLoading(true);
        let url = `${process.env.REACT_APP_API}/list?page=${currentPage}&limit=12`;
        const response = await fetch(url);
        const res_json = await response.json();
        if (res_json) {
          setPageCount(res_json?.Count || 0);
          setMovies(res_json.Movies || []);
        }
      } catch (error) {
        console.log("Error fetching movies:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage, isSearch]);

  useEffect(() => {
    const fetchSearchPG = async () => {
      try {
        if (!isSearch) {
          return;
        }
        setIsLoading(true);
        let url = `${process.env.REACT_APP_API}/search?page=${searchCurrentPage}&query=${searchQuery}&limit=12`;
        const response = await fetch(url);
        const res_json = await response.json();
        if (res_json) {
          setPageCount(res_json?.Count || 0);
          setMovies(res_json.Movies || []);
        }
      } catch (error) {
        console.log("Error fetching movies:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (searchQuery != "") {
      fetchSearchPG();
    }
  }, [searchCurrentPage, isSearch]);

  const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };

  const fetchSearch = async () => {
    try {
      setIsLoading(true);
      let url = `${process.env.REACT_APP_API}/search?query=${searchQuery}&limit=12`;
      const response = await fetch(url);
      const res_json = await response.json();
      if (res_json) {
        setPageCount(res_json?.Count || 0);
        setMovies(res_json.Movies || []);
      }
    } catch (error) {
      console.log("Error fetching movies:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const debounceSearch = debounce(fetchSearch);

  useEffect(() => {
    setRnId(guidGenerator())
    if (searchQuery == "") {
      setSearchCurrentPage(1);
      setIsSearch(false);
    } else {
      setCurrentPage(1);
      setIsSearch(true);
    }
    debounceSearch();
  }, [searchQuery]);

  return (
    <div className="bg-black min-h-screen">
      <header
        className="p-4 flex flex-wrap"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <h1 className="text-3xl font-bold text-white mb-1">DDLMovies</h1>
        <input
          type="text"
          placeholder="Search ..."
          className="mt-2 p-2 border rounded-full px-4"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </header>
      {searchQuery != "" && (
        <div className="flex items-center justify-center w-full h-full">
          <div className="text-white text-lg font-bold">Search Result</div>
        </div>
      )}
      {isLoading && (
        <div className="fixed z-10 inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center w-full h-full">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-white border-opacity-25"></div>
        </div>
      )}
      <div className=" container mx-auto mt-8 px-4 grid gap-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
        {movies.map((movie) => (
          <Link
            to={`/movie/${movie.CID}`}
            key={movie.CID}
            target="_blank"
            className="w-full"
          >
            <div className="relative  overflow-hidden transition-transform duration-200 transform hover:scale-105 hover:z-10">
              <img
                src={`https://images.cdn.kukufm.com/w:350/f:webp/q:100/${movie.Info.PosterLink}`}
                alt={movie.Name}
                style={{ height: "22rem" }}
                className="w-full object-cover rounded-lg"
              />
              <div
                className="absolute bottom-0 left-0 p-2 bg-black bg-opacity-80 w-full text-white text-sm font-semibold truncate"
                style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)" }}
              >
                {movie.Name}
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div key={rnId}>
        <ReactPaginate
          previousLabel={
            <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
              <AiFillLeftCircle />
            </IconContext.Provider>
          }
          nextLabel={
            <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
              <AiFillRightCircle />
            </IconContext.Provider>
          }
          breakLabel="..."
          breakClassName="mx-3 text-white font-bold"
          pageCount={
            parseInt(pageCount / 12) >= 999
              ? 999
              : pageCount % 12 == 0
              ? parseInt(pageCount / 12)
              : parseInt(pageCount / 12) + 1
          }
          marginPagesDisplayed={4}
          pageRangeDisplayed={10}
          initialPage={
            searchQuery == "" ? currentPage - 1 : searchCurrentPage - 1
          }
          onPageChange={(event) =>
            searchQuery == ""
              ? (setCurrentPage(event.selected + 1), setSearchCurrentPage(1))
              : (setSearchCurrentPage(event.selected + 1), setCurrentPage(1))
          }
          containerClassName="flex flex-wrap justify-center items-center  my-10"
          activeClassName="bg-red-600 rounded-full  text-white active"
          pageClassName="mx-3  font-bold cursor-pointer text-white"
          previousClassName="mx-3  cursor-pointer"
          nextClassName="mx-3  cursor-pointer"
        />
      </div>
      <footer>
        <div className="flex w-full justify-center ">
      <span className="text-white font-semibold m-5">* This is UGC(User generated content) platfrom. If you want to add any post/content email at admin@ddlmovies.online. If you find any content in-appropriate or copywrited complaint us at admin@ddlmovies.online. Thanks :)</span>
      </div>
      </footer>
    </div>
  );
}

export default MovieListPage;
